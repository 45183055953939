var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"v2.4.7"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs"

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const SENTRY_ENVIRONMENT = process.env.SENTRY_ENVIRONMENT || process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT
const SENTRY_ENABLED = process.env.NEXT_PUBLIC_SENTRY_ENABLED

if (SENTRY_ENABLED === "enabled") {
  Sentry.init({
    beforeSend(event: Sentry.Event) {
      if (
        event?.exception?.values &&
        event.exception.values[0]?.stacktrace?.frames &&
        event.exception.values[0].stacktrace.frames[0].filename === `<anonymous>`
      ) {
        return null
      }

      return event
    },

    dsn: SENTRY_DSN,
    environment: SENTRY_ENVIRONMENT,

    ignoreErrors: [
      // https://stackoverflow.com/questions/72720387/cant-find-variable-autofillcallbackhandler
      "ReferenceError: Can't find variable: _AutofillCallbackHandler",
    ],

    tracesSampler: (samplingContext) => {
      // disable tracing for the liveness probe
      if (samplingContext.transactionContext.name.includes("healthz")) {
        return 0.0
      }
      return 0.01
    },
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  })
}
